import useSessionStore from "@/store/session"
import { settingsStore } from "@/store/settings"
import * as React from "react"

const AdRefinery = React.lazy(() => import("./refinery"))
const SetupAd = React.lazy(() => import("./setupad"))

interface Props {
  location: string
  type: string
}

export function DisableInterstitialAd() {
  React.useEffect(() => {
    const disableInterstitial = () => {
      // Make sure googletag is available
      if (
        typeof window !== "undefined" &&
        window.googletag &&
        window.googletag.pubads
      ) {
        try {
          // Get all slots
          const slots = window.googletag.pubads().getSlots()
          // Find the interstitial slot
          const interstitialSlot = slots.find(
            (slot) =>
              slot.getAdUnitPath() ===
              "/147246189,22724240645/lapalabradeldia.com_interstitial"
          )
          // Destroy it if found
          if (interstitialSlot) {
            window.googletag.destroySlots([interstitialSlot])
            console.log("Successfully disabled interstitial ad")
          }
        } catch (error) {
          console.error("Error disabling interstitial ad:", error)
        }
      }
    }

    // Run the disable function
    disableInterstitial()

    // Clean up function
    return () => {
      // Add any cleanup if needed
    }
  }, []) // Empty dependency array means this runs once on mount

  // This component doesn't need to render anything
  return null
}

export default function AdBanner(props: Props) {
  const adContainerRef = React.useRef<HTMLDivElement>(null)
  const [adLoaded, setAdLoaded] = React.useState(false)

  const isDev = settingsStore((state) => state.dev)
  const session = useSessionStore.use.session()

  React.useEffect(() => {
    // if (isDev) return

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && adContainerRef.current) {
          // Check if ref is available
          setAdLoaded(true)
          observer.disconnect()
        }
      })
    })

    if (adContainerRef.current) {
      // Check if ref is available before observing
      observer.observe(adContainerRef.current)
    }

    return () => {
      if (adContainerRef.current) {
        observer.unobserve(adContainerRef.current)
      }
    }
  }, [])

  if (session !== undefined && session !== null) {
    return null
  }

  const isOversized = false
  const adContainerStyle: React.CSSProperties = {
    overflow: "hidden",
  }

  return (
    <div ref={adContainerRef} className="bg-neutral-200 dark:bg-neutral-900">
      {session !== undefined && session !== null ? (
        <DisableInterstitialAd />
      ) : null}

      {props.location == "top" ? (
        <div
          id="ad-banner-top"
          className="max-w-content relative mx-auto block w-full md:hidden"
          style={{
            ...adContainerStyle,
            height: `${props.type == "setupad-50" || props.type == "refinery-50" ? "70px" : "120px"}`,
            maxHeight: "120px",
          }}
        >
          {isDev ? (
            <span className="absolute bottom-0 right-0">{props.type}</span>
          ) : null}

          {props.type == "setupad-50" ? (
            <React.Suspense fallback={<div className="h-[50px]" />}>
              <SetupAd
                height="50px"
                width="350px"
                id="lapalabradeldia_billboard_mobile"
                adLoaded={adLoaded}
                isOversized={isOversized}
              />
            </React.Suspense>
          ) : null}

          {props.type == "setupad-100" ? (
            <React.Suspense fallback={<div className="h-[100px]" />}>
              <SetupAd
                height="100px"
                width="350px"
                id="lapalabradeldia_billboard_mobile"
                adLoaded={adLoaded}
                isOversized={isOversized}
              />
            </React.Suspense>
          ) : null}

          {props.type == "refinery-50" ? (
            <React.Suspense fallback={<div className="h-[50px]" />}>
              <AdRefinery
                height="50px"
                width="350px"
                className="r89-mobile-billboard-top50"
                adLoaded={adLoaded}
                isOversized={isOversized}
              />
            </React.Suspense>
          ) : null}

          {props.type == "refinery-100" ? (
            <React.Suspense fallback={<div className="h-[100px]" />}>
              <AdRefinery
                height="100px"
                width="100px"
                className="r89-mobile-billboard-top"
                adLoaded={adLoaded}
                isOversized={isOversized}
              />
            </React.Suspense>
          ) : null}

          <div
            className="ad-banner-bottom-spacing"
            style={{
              height: "20px",
              maxHeight: "20px",
            }}
          ></div>
        </div>
      ) : null}
      {props.location == "bottom" ? (
        <div
          id="ad-banner-bottom"
          style={{
            height: "130px",
            maxHeight: "130px",
          }}
          className="relative flex hidden flex-col justify-items-end md:block"
        >
          {isDev ? (
            <span className="absolute right-0 top-0">{props.type}</span>
          ) : null}

          <div
            className="ad-banner-bottom-spacing"
            style={{
              ...adContainerStyle,
              height: "30px",
              maxHeight: "30px",
            }}
          ></div>

          {props.type == "setupad" ? (
            <React.Suspense fallback={<div className="h-[100px]" />}>
              <SetupAd
                height="100px"
                width="1000px"
                id="lapalabradeldia_1000x100_desktop"
                adLoaded={adLoaded}
              />
            </React.Suspense>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
